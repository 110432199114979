.small {
  font-size: inherit;
}

.medium {
  font-size: 3rem;
}

.large {
  font-size: 7rem;
}
