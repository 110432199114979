@import '../../styles/variables';

.buttonIcon {
  margin-right: 10px;
}

.primary,
.secondary {
  font-family: $font-primary;
  border: 2px solid $color-secondary;
  border-radius: 3px;
  outline: none;
  cursor: pointer;

  &:hover {
    border-color: $color-secondary-light;
  }
}

.primary {
  color: $color-primary-light;
  background: $color-secondary;

  &:hover {
    background: $color-secondary-light;
  }
}

.secondary {
  color: $text-primary;
  background: inherit;

  &:hover {
    color: $text-primary-light;
  }
}

.disabledButton {
  cursor: default;
  pointer-events: none;
}

.small {
  font-size: 1.4rem;
  padding: 3px 6px;
}

.medium {
  font-size: 1.6rem;
  padding: 5px 10px;
}

.large {
  font-size: 1.8rem;
  padding: 8px 14px;
}
