// Container width
$max-width: 800px;

// Text colors
$text-primary: #3b5998;
$text-primary-light: #207bec;
$text-secondary: #000000;
$text-secondary-light: #202020;

// Background, borders, etc
$color-primary: #f0f3f5;
$color-primary-light: #ffffff;
$color-secondary: #3b5998;
$color-secondary-light: #207bec;
$color-tertiary: #000000;
$color-tertiary-light: #202020;
$color-quaternary: #cccccc;

// Fonts
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Domine', serif;
