@import '../../styles/variables';

.iconButton {
  padding: 5px 10px;
  background: inherit;
  font-family: $font-primary;
  border: none;
  outline: none;
  cursor: pointer;
}

.primary {
  color: $text-primary;

  &:hover {
    color: $text-primary-light;
  }
}

.secondary {
  color: $text-secondary;

  &:hover {
    color: $text-secondary-light;
  }
}

.small {
  font-size: 1.8rem;
}

.medium {
  font-size: 2.2rem;
}

.large {
  font-size: 2.6rem;
}
