@import '../../styles/variables';

.dailyForecast {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid $color-quaternary;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.itemsCenter {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  p {
    margin: 0 0 0 5px;
  }
}
