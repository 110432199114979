@import '../../styles/variables';

.searchBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchFormContainer {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.searchForm {
  display: flex;
  height: 40px;
  border: 2px solid $color-secondary;

  .submitButton {
    background: $color-primary-light;
  }
}

.textInput {
  width: 100%;
  padding: 5px 10px;
  font-size: 1.6rem;
  font-family: $font-primary;
  color: $text-secondary;
  border: none;
  outline-color: $color-quaternary;
  outline-offset: -3px;
}

.errorMessageContainer {
  min-height: 6rem; // rems - must scale!
  padding: 10px 0;
}
