.currentWeather {
  position: relative;
}

.currentWeatherHeader {
  display: flex;

  h2 {
    flex-grow: 1;
    margin-bottom: 0px;
  }

  .favoriteButton {
    color: #f0b400;

    &:hover {
      color: #ffc517;
    }
  }
}

.locationIcon {
  margin-right: 10px;
}

.currentWeatherInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// .loadingSpinner {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   & > * {
//     padding: 10px;
//     background: rgba(255, 255, 255, 0.8);
//     font-weight: 500;
//   }
// }
