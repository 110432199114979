@import '@csstools/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');
@import 'variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: $color-primary;
  color: $text-primary;
  font-size: 1.6rem;
  font-family: $font-primary;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
  font-family: $font-secondary;
  margin: 1.6rem 0;
}

p {
  margin: 1rem 0;
}