@import url(https://fonts.googleapis.com/css2?family=Domine:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap);
*{margin:0;padding:0;box-sizing:border-box}html{font-size:62.5%}body{background:#f0f3f5;color:#3b5998;font-size:1.6rem;font-family:"Roboto",sans-serif}h1,h2,h3,h4,h5,h5,h6{font-family:"Domine",serif;margin:1.6rem 0}p{margin:1rem 0}

.App_container__1rLlW{max-width:800px;margin:0 auto;padding:0 10px}

.Header_header__c02Ou{text-align:center;padding-top:30px}

.SearchBar_searchBar__3HUBO{display:flex;flex-direction:column;align-items:center}.SearchBar_searchFormContainer__2Fjly{position:relative;width:500px;max-width:100%}.SearchBar_searchForm__2MKKG{display:flex;height:40px;border:2px solid #3b5998}.SearchBar_searchForm__2MKKG .SearchBar_submitButton__1NSVy{background:#fff}.SearchBar_textInput__2EtOI{width:100%;padding:5px 10px;font-size:1.6rem;font-family:"Roboto",sans-serif;color:#000;border:none;outline-color:#ccc;outline-offset:-3px}.SearchBar_errorMessageContainer__2LYcO{min-height:6rem;padding:10px 0}

.IconButton_iconButton__ZuKel{padding:5px 10px;background:inherit;font-family:"Roboto",sans-serif;border:none;outline:none;cursor:pointer}.IconButton_primary__1-B_M{color:#3b5998}.IconButton_primary__1-B_M:hover{color:#207bec}.IconButton_secondary__2yM3y{color:#000}.IconButton_secondary__2yM3y:hover{color:#202020}.IconButton_small__1oENm{font-size:1.8rem}.IconButton_medium__1ul3r{font-size:2.2rem}.IconButton_large__2j_WK{font-size:2.6rem}

.SearchBarDropdown_searchBarDropdown__2p7Fo{position:absolute;z-index:999;width:100%;list-style:none;background:#fff;border:2px solid #3b5998;border-top:0}.SearchBarDropdown_searchBarDropdown__2p7Fo li{padding:10px;border-bottom:2px solid #ccc;cursor:pointer}.SearchBarDropdown_searchBarDropdown__2p7Fo li:last-child{border-bottom:0}.SearchBarDropdown_searchBarDropdown__2p7Fo li:hover{background-color:#f0f3f5}.SearchBarDropdown_searchBarDropdown__2p7Fo li:hover .SearchBarDropdown_icon__13IrJ{color:#000}.SearchBarDropdown_icon__13IrJ{color:#ccc;margin-right:10px}

.ErrorMessage_error__31nY7{color:#ff5733;text-align:center}

.FavoriteLocations_locationsHeader__3s9TQ{display:flex;align-items:center}.FavoriteLocations_locationsHeader__3s9TQ h2{flex-grow:1}.FavoriteLocations_menuIcon__1bw-n{display:none}.FavoriteLocations_locationsMenu__129kM{display:flex;flex-flow:row wrap;list-style:none}.FavoriteLocations_locationsMenu__129kM li{margin-right:10px;margin-bottom:10px}.FavoriteLocations_locationsMenu__129kM li:last-child{margin-right:0}.FavoriteLocations_locationsMenu__129kM .FavoriteLocations_mobileButton__2J71f{color:#3b5998;background:none;border:none}.FavoriteLocations_locationsMenu__129kM .FavoriteLocations_mobileButton__2J71f:hover{color:#207bec;background:#f0f3f5}@media only screen and (max-width: 600px){.FavoriteLocations_locationsMenu__129kM{display:none}.FavoriteLocations_locationsMenu__129kM.FavoriteLocations_locationsMenuActive__2ixzf{display:flex}.FavoriteLocations_locationsMenu__129kM li{flex-basis:100%}.FavoriteLocations_mobileButton__2J71f{width:100%}.FavoriteLocations_menuIcon__1bw-n{display:block}}

.Card_card__3P6gA{margin-bottom:20px;padding:5px 10px 15px 10px;background:#fff;box-shadow:0 0 10px #ccc}

.Button_buttonIcon__iKcyW{margin-right:10px}.Button_primary__nVAAh,.Button_secondary__20XlY{font-family:"Roboto",sans-serif;border:2px solid #3b5998;border-radius:3px;outline:none;cursor:pointer}.Button_primary__nVAAh:hover,.Button_secondary__20XlY:hover{border-color:#207bec}.Button_primary__nVAAh{color:#fff;background:#3b5998}.Button_primary__nVAAh:hover{background:#207bec}.Button_secondary__20XlY{color:#3b5998;background:inherit}.Button_secondary__20XlY:hover{color:#207bec}.Button_disabledButton__388VU{cursor:default;pointer-events:none}.Button_small__INaBb{font-size:1.4rem;padding:3px 6px}.Button_medium__1kqsT{font-size:1.6rem;padding:5px 10px}.Button_large__3maeV{font-size:1.8rem;padding:8px 14px}

.CurrentWeather_currentWeather__2Iyty{position:relative}.CurrentWeather_currentWeatherHeader__2uWtc{display:flex}.CurrentWeather_currentWeatherHeader__2uWtc h2{flex-grow:1;margin-bottom:0px}.CurrentWeather_currentWeatherHeader__2uWtc .CurrentWeather_favoriteButton__k2z1X{color:#f0b400}.CurrentWeather_currentWeatherHeader__2uWtc .CurrentWeather_favoriteButton__k2z1X:hover{color:#ffc517}.CurrentWeather_locationIcon__c43pD{margin-right:10px}.CurrentWeather_currentWeatherInfo__2iix5{display:flex;flex-wrap:wrap;justify-content:space-around}.CurrentWeather_flexColumn__3cuaD{display:flex;flex-direction:column;align-items:center}

.TemperatureCard_small__2-b30{font-size:inherit}.TemperatureCard_medium__2JcwU{font-size:3rem}.TemperatureCard_large__1ptnM{font-size:7rem}

.WeatherIcon_small__1uxTE,.WeatherIcon_large__2mFRu{display:block;height:auto}.WeatherIcon_small__1uxTE{width:3rem}.WeatherIcon_medium__2_Wv_{width:4rem}.WeatherIcon_large__2mFRu{width:10rem}

.DailyForecast_dailyForecast__9YUOh{display:flex;align-items:center;margin-bottom:10px;padding-bottom:10px;border-bottom:2px solid #ccc}.DailyForecast_dailyForecast__9YUOh:last-child{border-bottom:0;padding-bottom:0}.DailyForecast_itemsCenter__FpGbC{flex-grow:1;display:flex;flex-direction:column;margin:0 20px}.DailyForecast_itemsCenter__FpGbC p{margin:0 0 0 5px}

.Loader_loader__36HN6{display:flex;justify-content:center;align-items:center}.Loader_fullPage___Hunw{align-items:center;position:fixed;top:0;left:0;width:100%;height:100%;z-index:999;font-weight:500}

