.small,
.large {
  display: block;
  height: auto;
}

.small {
  width: 3rem;
}

.medium {
  width: 4rem;
}

.large {
  width: 10rem;
}
